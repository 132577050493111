<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col md="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                    <ValidationProvider name="Organization Name" vid="org_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="organization_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{$t('org_pro.organization')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="laboratory.org_id"
                          :options="organizationList"
                          id="organization_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="office_type_id"
                        slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('org_pro.office_type') }} <span class="text-danger">*</span>
                          </template>
                        <b-form-select
                          plain
                          v-model="laboratory.office_type_id"
                          :options="officeTypeList"
                          id="office_type_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="division_id"
                        slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('org_pro_division.division') }} <span class="text-danger">*</span>
                          </template>
                        <b-form-select
                          plain
                          v-model="laboratory.division_id"
                          :options="divisionList"
                          id="division_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="district_id"
                        slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('org_pro_district.district') }} <span class="text-danger">*</span>
                          </template>
                        <b-form-select
                          plain
                          v-model="laboratory.district_id"
                          :options="districtList"
                          id="district_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Upazilla" vid="upazilla_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="upazilla_id"
                        slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('org_pro_upazilla.upazilla') }} <span class="text-danger">*</span>
                          </template>
                        <b-form-select
                          plain
                          v-model="laboratory.upazilla_id"
                          :options="upazillaList"
                          id="upazilla_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Laboratory Name (En)" vid="laboratory_name" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="laboratory_name"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                          {{$t('irrigation_config.laboratory_name')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="laboratory_name"
                          v-model="laboratory.laboratory_name"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Laboratory Name (Bn)" vid="laboratory_name_bn" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="laboratory_name_bn"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                          {{$t('irrigation_config.laboratory_name_bn')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="laboratory_name_bn"
                          v-model="laboratory.laboratory_name_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Laboratory Address (En)" vid="address" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="address"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                          {{$t('irrigation_config.laboratory_address')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="address"
                          v-model="laboratory.address"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Laboratory Name (Bn)" vid="address_bn" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="address_bn"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                          {{$t('irrigation_config.laboratory_address_bn')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="address_bn"
                          v-model="laboratory.address_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { laboratoryStore, laboratoryUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      laboratory: {
        laboratory_name: '',
        laboratory_name_bn: '',
        address: '',
        address_bn: '',
        org_id: 0,
        office_type_id: 0,
        division_id: 0,
        district_id: 0,
        upazilla_id: 0
      },
      organizationList: [],
      districtList: [],
      upazillaList: [],
      unionList: []
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getlaboratoryData()
      this.laboratory = tmp
    }
    this.getOrganizationlist()
  },
  computed: {
    officeTypeList: function () {
      return this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0 && item.org_id === this.laboratory.org_id)
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    }
  },
  watch: {
    'laboratory.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'laboratory.district_id': function (newVal, oldVal) {
      this.upazillaList = this.getUpazilaList(newVal)
    },
    'laboratory.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    getlaboratoryData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      const officeKeys = {
        area_type_id: 3,
        country_id: 1,
        office_name: this.laboratory.laboratory_name,
        office_name_bn: this.laboratory.laboratory_name_bn,
        office_code: 'LAB-1'
      }
      this.laboratory = Object.assign({}, this.laboratory, officeKeys)
      if (this.laboratory.id) {
        result = await RestApi.putData(irriSchemeServiceBaseUrl, `${laboratoryUpdate}/${this.id}`, this.laboratory)
      } else {
        result = await RestApi.postData(irriSchemeServiceBaseUrl, laboratoryStore, this.laboratory)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('IrriConfig/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getOrganizationlist () {
      const orgList = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      this.organizationList = orgList.map(obj => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }
      return unionList
    }
  }
}
</script>
